/* Header.css */

.header {
    position: relative;
    text-align: center;
    color: white;
  }
  
  .carousel .slide img {
    width: 100%;
    height: 500px;
    object-fit: cover;
  }
  
  .hero {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    padding: 20px;
    border-radius: 10px;
  }
  

  
  .hero h1 {
    font-size: 2.5em;
    margin-bottom: 10px;
  }
  
  .hero p {
    font-size: 1.2em;
    margin-bottom: 0;
  }
  