body {
  font-family: Arial, sans-serif !important;
  margin: 0 !important;
  padding: 0 !important;
  box-sizing: border-box !important;
  background-color: #f8f9fa !important;
  color: #333 !important;
}

.container {
  max-width: 800px !important;
  margin: 0 auto !important;
  padding: 20px !important;
}

.form-card {
  background-color: #fff !important;
  border-radius: 10px !important;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1) !important;
  padding: 30px !important;
  text-align: center !important;
}

.form-card h2 {
  font-size: 2em !important;
  color: #4169e1 !important; /* Royal Blue */
  margin-bottom: 10px !important;
}

.form-card p {
  font-size: 1.2em !important;
  color: #666 !important;
  margin-bottom: 20px !important;
}

.form-group {
  margin-bottom: 15px !important;
  text-align: left !important;
}

.form-group label {
  display: block !important;
  margin-bottom: 5px !important;
  font-weight: bold !important;
}

.form-group input,
.form-group select,
.form-group textarea {
  width: 100% !important;
  padding: 10px !important;
  margin-bottom: 5px !important;
  border: 1px solid #ccc !important;
  border-radius: 5px !important;
  font-size: 16px !important;
}

.form-group textarea {
  resize: vertical !important;
}

button[type="submit"] {
  background-color: #4169e1 !important; /* Royal Blue */
  color: white !important;
  border: none !important;
  padding: 10px 20px !important;
  font-size: 18px !important;
  border-radius: 5px !important;
  cursor: pointer !important;
  transition: background-color 0.3s !important;
  margin-top: 10px !important;
}

button[type="submit"]:hover {
  background-color: #3554b2 !important;
}

.opt-in {
  display: flex !important;
  align-items: center !important;
  justify-content: flex-start !important;
}

.opt-in label {
  display: flex !important;
  align-items: center !important;
}

.opt-in .tooltip {
  display: inline-block !important;
  position: relative !important;
  cursor: pointer !important;
  margin-left: 5px !important;
}

.opt-in .tooltip .tooltiptext {
  visibility: hidden !important;
  width: 220px !important;
  background-color: #333 !important;
  color: #fff !important;
  text-align: center !important;
  border-radius: 5px !important;
  padding: 5px !important;
  position: absolute !important;
  z-index: 1 !important;
  bottom: 125% !important;
  left: 50% !important;
  margin-left: -110px !important;
  opacity: 0 !important;
  transition: opacity 0.3s !important;
}

.opt-in .tooltip:hover .tooltiptext {
  visibility: visible !important;
  opacity: 1 !important;
}

@media (max-width: 768px) {
  .container {
    padding: 15px !important;
  }

  .form-group input,
  .form-group select,
  .form-group textarea {
    font-size: 14px !important;
  }

  button[type="submit"] {
    font-size: 16px !important;
  }
}
