/* App.css */

/* General Styles */
body {
  font-family: Arial, sans-serif;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  background-color: #f8f9fa;
  color: #333;
}

.container {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
}

.form-group {
  margin-bottom: 15px;
}

.form-group label {
  display: block;
  margin-bottom: 5px;
  font-weight: bold;
}

.form-group input,
.form-group select,
.form-group textarea {
  width: 100%;
  padding: 10px;
  margin-bottom: 5px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 16px;
}

.form-group textarea {
  resize: vertical;
}

button[type="submit"] {
  background-color: #4169e1; /* Royal Blue */
  color: white;
  border: none;
  padding: 10px 20px;
  font-size: 18px;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
}

button[type="submit"]:hover {
  background-color: #3554b2;
}

.notification {
  display: none;
  margin-top: 20px;
  padding: 15px;
  border-radius: 5px;
  font-size: 16px;
  color: white;
  text-align: center;
}

.notification.success {
  background-color: #28a745;
}

.notification.error {
  background-color: #dc3545;
}

.header {

  color: white;
  text-align: center;
}

/* .header .hero {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
} */

.header .hero .logo {
  width: 200px;
  height: 200px;
  border-radius: 600px;
}

.header h1 {
  font-size: 2.5em;
}

.header p {
  font-size: 1.2em;
  color: #f8f9fa;
}

.footer {
  background-color: #4169e1; /* Royal Blue */
  color: white;
  padding: 20px;
  text-align: center;
}

.footer-links {
  margin-bottom: 10px;
}

.footer-links a {
  color: #ccc;
  margin: 0 10px;
  text-decoration: none;
  transition: color 0.3s;
}

.footer-links a:hover {
  color: white;
}

.page-container {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.content-wrap {
  flex: 1;
}

@media (max-width: 768px) {
  .form-group input,
  .form-group select,
  .form-group textarea {
    font-size: 14px;
  }

  button[type="submit"] {
    font-size: 16px;
  }

  .header .hero .logo {
    width: 100px;
    height: 100px;
  }

  .header h1 {
    font-size: 24px;
  }

  .header p {
    font-size: 16px;
  }
}
