/* Services.css */

.services-container {
  padding: 40px 20px;
  background-color: #f5f5f5;
  color: #333;
  font-family: 'Arial, sans-serif';
}

.services-header {
  text-align: center;
  margin-bottom: 40px;
}

.services-header h1 {
  font-size: 2.5em;
  color: #4169e1; /* Royal Blue */
  margin-bottom: 10px;
}

.services-header p {
  font-size: 1.2em;
  color: #666;
  margin-bottom: 20px;
}

.services-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  gap: 10px;
}

.service-item {
  background-color: #fff;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  text-align: center;
  flex: 1 1 calc(33% - 40px);
  max-width: calc(33% - 40px);
  transition: transform 0.3s, box-shadow 0.3s;
}

.service-item:hover {
  transform: translateY(-10px);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

.service-icon {
  width: 100px;
  height: 100px;
  margin-bottom: 20px;
  object-fit: cover;
  border-radius: 50%;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.service-item h2 {
  font-size: 1.5em;
  color: #333;
  margin-bottom: 10px;
}

.service-item p {
  font-size: 1em;
  color: #555;
  line-height: 1.6;
}

@media (max-width: 768px) {
  .services-container {
    padding: 20px;
  }

  .services-list {
    flex-direction: column;
    align-items: center;
  }

  .service-item {
    max-width: 100%;
    margin-bottom: 20px;
  }
}
