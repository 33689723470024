.privacy-container {
    padding: 40px 20px;
    background-color: #f5f5f5;
    color: #333;
    font-family: 'Arial, sans-serif';
  }
  
  .privacy-header {
    text-align: center;
    margin-bottom: 40px;
  }
  
  .privacy-header h1 {
    font-size: 2.5em;
    color: #4169e1; /* Royal Blue */
  }
  
  .privacy-header p {
    font-size: 1.2em;
    color: #666;
  }
  
  .privacy-content {
    max-width: 800px;
    margin: 0 auto;
    background-color: #fff;
    padding: 30px;
    border-radius: 10px;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
  }
  
  .privacy-section {
    margin-bottom: 30px;
  }
  
  .privacy-section h2 {
    font-size: 2em;
    color: #4169e1; /* Royal Blue */
    margin-bottom: 10px;
  }
  
  .privacy-section p {
    font-size: 1.1em;
    color: #555;
    line-height: 1.6;
  }
  
  @media (max-width: 768px) {
    .privacy-container {
      padding: 15px;
    }
  
    .privacy-content {
      padding: 20px;
    }
  }
  